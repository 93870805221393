import React from 'react';
import PropTypes from 'prop-types';
import {
  Jumbotron,
  Container,
} from 'reactstrap';

const FluidJumbotron = ({ title, description }) => (
  <Jumbotron fluid className="bg-secondary py-5">
    <Container>
      <div className="content-center d-flex justify-content-center align-items-center flex-column">
        <h2 className="mt-0 mt-sm-5 text-white">{title}</h2>
        <p className="mb-0 mb-sm-5 text-white">{description}</p>
      </div>
    </Container>
  </Jumbotron>
);

FluidJumbotron.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

FluidJumbotron.defaultProps = {
  title: '',
  description: '',
};

export default FluidJumbotron;
