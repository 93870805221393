import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import ContactForm from './ContactForm';
import GoogleMap from './GoogleMap';

const QuickForm = () => (
  <div id="contacts" className="section section-bg">
    <Container>
      <div className="section-header mb-5">
        <h2 className="text-uppercase">Contatti</h2>
      </div>
      <Row>
        <Col sm="6" className="d-flex flex-column">
          <ContactForm />
        </Col>
        <Col sm="6" className="mt-2 mt-sm-0">
          <GoogleMap />
        </Col>
      </Row>
    </Container>
  </div>
);


export default QuickForm;
