import React from 'react';
import PropTypes from 'prop-types';
import InViewMonitor from 'react-inview-monitor';
import { graphql } from 'gatsby';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import Markdown from 'react-markdown';
import SEO from '../components/SEO';
import Layout from '../components/layout';
import PageHeader from '../components/Sections/PageHeader';
import QuickForm from '../components/Sections/QuickForm';
import FluidJumbotron from '../components/Sections/FluidJumbotron';

const TextAndImage = ({ title, description, image }) => (
  <Row className="cms-text">
    <Col sm="12">
      {title ? <h3 className="font-weight-bold font-family-base">{title}</h3> : ''}
      <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
        {description ? <Markdown source={description} /> : ''}
        <div className="my-5">
          {image ? <img src={image.sizes.src} alt={title} className="img-fluid" /> : ''}
        </div>
      </InViewMonitor>
    </Col>
  </Row>
);

TextAndImage.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.shape({
    sizes: PropTypes.shape({
      src: PropTypes.string,
    }),
  }),
};

TextAndImage.defaultProps = {
  title: undefined,
  description: undefined,
  image: undefined,
};

const Feature = ({ point, title, description }) => (
  <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
    <Row key={point} className="point point-lg border-bottom">
      <Col sm="6">
        <h6 className="text-primary">{point}</h6>
        <h2 className="text-primary font-weight-bold font-family-base h4">{title}</h2>
      </Col>
      <Col sm="6">
        <Markdown source={description} />
      </Col>
    </Row>
  </InViewMonitor>
);

Feature.propTypes = {
  point: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

function renderComponent(module) {
  switch (module.internal.type) {
    case 'DatoCmsTextAndImage':
      return <TextAndImage key={module.id} {...module} />;
    case 'DatoCmsFeature':
      return <Feature key={module.id} {...module} />;
    default:
      return '';
  }
}

const PageTemplate = ({ data }) => {
  const page = data.datoCmsPage;
  return (
    <Layout>
      <SEO title={page.title} />
      <PageHeader title={page.title} description={page.description} />
      <div id="page" className="section">
        <Container>
          {page.modules.length > 0
            ? page.modules.map(module => renderComponent(module))
            : ''}
        </Container>
      </div>
      { page.highlightTitle || page.highlightDescription
        ? <FluidJumbotron title={page.highlightTitle} description={page.highlightDescription} />
        : null }
      {page.slug === 'cookie-policy' || page.slug === 'privacy' ? null : <QuickForm />}
    </Layout>
  );
};

PageTemplate.propTypes = {
  data: PropTypes.shape({
    datoCmsPage: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      slug: PropTypes.string,
      highlightTitle: PropTypes.string,
      highlightDescription: PropTypes.string,
      modules: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export default PageTemplate;

export const query = graphql`
  query PageTemplate($slug: String!) {
    datoCmsPage(slug: {eq: $slug}) {
      title
      description
      highlightTitle
      highlightDescription
      slug
      modules {
        ... on DatoCmsFeature {
          id
          point
          title
          description
          internal {
            type
          }
        }
        ... on DatoCmsTextAndImage {
          id
          title
          description
          image {
            sizes (imgixParams: {fm: "jpg", fit: "crop", crop: "focalpoint", h: "500", w: "1600"}) {
              src
            }
          }
          internal {
            type
          }
        }
      }

    }
  }
`;
